.l-grid {
    display: flex;
    flex: 0 1 auto;
    flex-flow: row wrap;
}

.c-tracking--container {
    background-color: #f2f2f2;
}

.l-grid--center-s {
    justify-content: center;
    text-align: center;
}

.l-grid--w-100pc-s {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
}

.c-tracking-result--loader {
    display: none;
    margin: 5rem 0;
}

.sr-only {
    clip: rect(0,0,0,0);
    border: 0;
    font-size: 1rem;
    height: .1rem!important;
    margin: -.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute!important;
    width: .1rem!important;
}

.c-tracking-result--container {
    background-color: #fff;
    margin: 0 auto;
    max-width: 89.3rem;
    width: 100%;
}

@media print, screen and (min-width: 768px){
    .c-tracking-result--container {
        border: .1rem solid #8c8c8c;
        border-radius: .4rem;
        margin-bottom: 2.8rem;
    }
}

.aem-Grid:after,.aem-Grid:before {
    content: " ";
    display: table;
}

.aem-Grid:after {
    clear: both;
}

.c-tracking-result--header {
    flex-grow: 1;
}

.c-voc-tracking-bar--form {
    display: flex;
    flex-flow: column wrap;
}

@media print, screen and (min-width: 768px){
    .c-voc-tracking-bar--form {
        flex-direction: row;
        justify-content: start;
    }
}

.c-tracking-input--form .c-voc-tracking-bar--form  {
    width: 100%;
}

.c-tracking-input--form {
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 89.3rem;
}

@media print, screen and (min-width: 768px){
    .c-tracking-input--form {
        align-items: center;
    }

    .c-tracking-input--form ,.c-tracking-input--container .c-tracking-input--form  {
        max-width: 58.8rem;
    }
}

.c-tracking-input--container {
    background-color: #f2f2f2;
    padding: 2.1rem;
}

@media print, screen and (min-width: 768px){
    .c-tracking-input--container {
        background-color: #f2f2f2;
        padding: 2.8rem;
    }
}

p {
    margin-bottom: 1.4rem;
}

.c-form--element-base {
    background-color: #fff;
    border: 0;
    color: #191919;
    display: block;
    font-size: 1.8rem;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    width: 100%;
}

.c-form-step--error-message {
    background: #fff url(https://www.dhl.com/etc/clientlibs/dhl/clientlib-all/assets/img/e4a4053ac5d5e2949677.svg) left top .2rem no-repeat;
    background-size: 1.6rem 1.6rem;
    color: #d40511;
    display: none;
    margin-top: .7rem;
    padding: .1rem 0 0 2.3rem;
    background-image: url("https://www.dhl.com/etc/clientlibs/dhl/clientlib-all/assets/img/e4a4053ac5d5e2949677.svg");
    background-position-x: left;
    background-position-y: top 0.2rem;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(255, 255, 255);
}

.c-form-step--error-message {
    font-size: 1.4rem;
    line-height: 1.3;
}

.c-tracking-result--section {
    border-bottom: .1rem solid #b2b2b2;
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 2.1rem;
    position: relative;
    width: 100%;
}

@media print, screen and (min-width: 768px){
    .c-tracking-result--section {
        padding: 2.8rem;
    }
}

.c-tracking-result--section.c-tracking-result--info {
    border-bottom: none;
    display: flex;
    flex-wrap: wrap;
}

@media print, screen and (min-width: 768px){
    .c-tracking-result--section.c-tracking-result--info {
        flex-wrap: no-wrap;
    }

    .c-tracking-result--section:last-child {
        border-bottom: none;
    }
}

.c-navigation--bar:not(.text-zoomed) {
    height: 6.3rem;
    max-height: 6.3rem;
}

.c-tracking-result--section.c-tracking-result--info .c-tracking-result--status-shipment-undefined  {
    font-size: 2.8rem;
    margin-bottom: 1.4rem;
    width: 100%;
}

.c-tracking-result--section .has-icon.icon-information-outline-legacy  {
    font-size: 3.4rem;
}

@media print, screen and (min-width: 768px){
    .c-tracking-result--section.c-tracking-result--info .c-tracking-result--status-shipment-undefined  {
        margin-right: 2.8rem;
        width: auto;
    }

    .c-tracking-result--section .has-icon.icon-information-outline-legacy  {
        margin: 0 2.5rem 1.4rem -.3rem;
    }
}

.has-icon:after,.has-icon:before, [class*=" icon-"]::before,[class^="icon-"]:before {
    speak: none;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: dhlicons;
    font-size: inherit;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
}

.has-icon:after,.has-icon:before {
    position: relative;
}

.icon-information-outline-legacy:before {
    content: "\e60b";
}

.has-icon::after, .has-icon::before, [class*=" icon-"]::before, [class^="icon-"]::before {
    speak: none;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: dhlicons;
    font-size: inherit;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
}

.c-navigation--bar > div {
    display: flex;
    height: 100%;
}

.c-voc-tracking-bar--input-inner-group {
    background-color: #fff;
    border-radius: .4rem;
    display: flex;
    position: relative;
}

.c-voc-tracking-bar--input-outer-group {
    border: .1rem solid #8c8c8c;
    border-radius: .5rem;
}

@media print, screen and (min-width: 768px){
    .c-voc-tracking-bar--input-outer-group {
        flex: 0 1 100%;
    }
}

.c-tracking-input--form .c-voc-tracking-bar--input-outer-group  {
    border: .1rem solid #8c8c8c;
    border-radius: .5rem;
}

.c-voc-tracking-bar--element-label {
    color: #666;
    font-size: 1.6rem;
    overflow: hidden;
    padding: 1.7rem 1.4rem;
    position: absolute;
    text-overflow: ellipsis;
    transition: top .2s ease-out,padding .2s ease-out,padding-top .2s ease-out,font-size .2s ease-out,line-height .2s ease-out,background-color 1ms .2s;
    white-space: nowrap;
    width: calc(100% - 1.4rem);
}

[data-form-value-state="set"] .c-voc-tracking-bar--element-label  {
    font-size: 1rem;
    line-height: 1.3;
    padding-bottom: 0;
    padding-top: .9rem;
}

button {
    background: none;
    border: none;
}

button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button {
    overflow: visible;
}

button {
    text-transform: none;
}

button {
    line-height: inherit;
}

[type="submit"],button {
    -webkit-appearance: button;
}

.base-button {
    align-items: center;
    background-color: #d40511;
    border: .1rem solid #d40511;
    border-radius: .4rem;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 700;
    justify-content: center;
    letter-spacing: normal;
    margin: 0 0 1.4rem;
    min-height: 4.2rem;
    overflow: hidden;
    padding: .7rem 1.54rem;
    position: relative;
    text-align: center;
    vertical-align: middle;
}

.base-button {
    color: #fff;
    display: inline-flex;
    text-decoration: none;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    word-spacing: normal;
}

.base-button {
    min-width: 11.9rem;
}

@media print, screen and (min-width: 768px){
    .base-button {
        transition: background-color .2s,border .2s;
    }
}

.c-voc-tracking-bar--button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    flex: 0 0 auto;
    font-size: 1.6rem;
    margin: .2rem;
    min-width: 11rem;
    width: auto;
}

button.base-button {
    display: inline-block;
}

.c-tracking-input--form .c-voc-tracking-bar--button  {
    min-width: 11.2rem;
    width: auto;
}

.base-button:before {
    left: 1.4rem;
    position: absolute;
}

.base-button:after {
    position: absolute;
    right: 1.4rem;
}

.base-button:active,.base-button:hover {
    background-color: #eb131e;
    border-color: #eb131e;
}

h2 {
    text-transform: none;
}

@media print, screen and (min-width: 1024px){
    h2 {
        font-size: 3.6rem;
        line-height: 1.3;
    }
}

.level4,h2 {
    font-family: Delivery,Verdana,sans-serif;
    margin: 0 0 2.8rem;
}

.level4,h2 {
    font-weight: 800;
}

.level4 {
    font-size: 2.2rem;
    line-height: 1.3;
}

@media print, screen and (min-width: 768px){
    .c-tracking-result--code {
        margin-bottom: 2.1rem;
    }
}

.c-tracking-result--section .c-tracking-result--code  {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-bottom: 1.4rem;
    word-break: break-word;
}

.c-tracking-result--container span.is-hidden  {
    height: .1rem;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: .1rem;
}

.l-grid--left-s {
    justify-content: flex-start;
    text-align: left;
}

.c-tracking-result-message--content {
    font-size: 1.4rem;
    line-height: 1.5;
}


