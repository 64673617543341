.input_container {
  @apply flex flex-col  gap-2 text-left;
}

.input_label {
  @apply flex items-center font-medium text-[#00040E];
}

.input_textError {
  @apply text-red-500  tracking-normal ;
}
.input_style:focus {
  outline: none !important;
}

.passwordInput{
  outline: none !important;
}

.input_style  {
  -webkit-appearance: none;
  @apply  flex justify-center hover:border-blue-500 focus:border-blue-500 focus:ring-transparent   text-gray-600  h-[40px] text-[16px] px-2 cursor-pointer  ;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

.custom-react-select__input-container > [type="text"]:focus{
  @apply ring-transparent outline-none
}

.hide-date-calendar::-webkit-inner-spin-button,
.hide-date-calendar::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
input[type='date'] {
  @apply pr-5;
}
