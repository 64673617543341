
.col-md-4,.col-lg-4 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 992px){
    .col-md-4 {
        float: left;
    }

    .col-md-4 {
        width: 33.33333333%;
    }
}

@media (min-width: 1200px){
    .col-lg-4 {
        float: left;
    }

    .col-lg-4 {
        width: 33.33333333%;
    }
}

:before,:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:selection {
    color: white;
    text-shadow: none;
    background: #222222;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

table {
    background-color: transparent;
}

.parcel-attributes {
    width: 100%;
    margin-bottom: 1em;
}

.sharing-wrap {
    margin-top: 20px;
    margin-bottom: 2em;
}

.parcel-attributes tr:nth-child(2n + 1)  {
    background-color: #F3F8F9;
}

td {
    padding: 0;
}

.parcel-attributes td  {
    padding: 10px;
}

.parcel-attributes td.value  {
    text-align: right;
}

.flag-icon {
    margin-right: 10px;
}

.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
}

.flag-icon-np {
    background-image: url(https://dvow0vltefbxy.cloudfront.net/assets/flag-icons/np-69637ddb3a173c4522a71598278aa32462e1762e34dd99c9dc99a515433a6cf8.svg);
}

.flag-icon:before {
    content: "\00a0";
}

a {
    background-color: transparent;
}

a {
    color: #337ab7;
    text-decoration: none;
}

a {
    -webkit-transition: all 0.35s;
    -moz-transition: all 0.35s;
    transition: all 0.35s;
    color: #EF5E6A;
}

a {
    color: white;
}

.parcel-attributes a  {
    color: #337ab7;
    margin-left: 0.5em;
    line-height: 1.6em;
}

a:active,a:hover {
    outline: 0;
}

a:hover {
    color: #23527c;
    text-decoration: underline;
}

a:hover {
    color: #fcbd20;
}

a:hover {
    color: white;
}

.parcel-attributes a:hover {
    text-decoration: none;
    color: #286192;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-StCGy.style-StCGy {
    white-space: nowrap;
}
